import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1b444a",
    },
    secondary: {
      main: "#ED072A",
    },
    white: {
      main: "#FFFFFF",
    },
    highContrastLink: {
      main: "#0000FF",
      dark: "#00003D",
    },
    divider: "rgba(0,0,0,0.23)",
  },
  typography: {
    fontFamily: "Open Sans",
  },
});

export default theme;
